export default {
  imar: {
    user: { // These information will be filled after login
      id: -1,
      logged: false,
      name: null,
      fullName: null,
      email: null,
      err: false,
    },
    selectedAppId: -1,  // ID of the selected ARApp. -1 -> nothing selected
    selectedLayerId: -1,// ID of the selected layer. -1 -> nothing selected
    selectedImageId: -1,
    apps: [],
    layers: []
  } //*/


  //for debug, fake initial state:
  /*imar: {
    user: {
      id: 1234,
      googleId: 2345,
      logged: true,
      name: 'joe'
    },
    selectedAppId: 1,
    selectedLayerId: 7,
    selectedImageId: -1,
    apps: [
      {
        label: 'firstApp',
        url: 'https://deploymentURL.com',
        id: 1,
        layers: [2,6]
      },
      {
        label: 'secondApp',
        url: 'https://deploymentURL2.com',
        id: 2
      }
    ],
    layers: [
      {
        label: 'ARLAYER1',
        id: 6,
        images: [
          {
            id: 0,
            label: 'CUP'
          },
          {
            id: 1,
            label: 'CAT'
          }
        ]
      },
      {
        label: 'ARLAYER2',
        id: 7,
        images: [
          {
            id: 0,
            label: 'HAT'
          },
          {
            id: 1,
            label: 'HOUSE'
          }
        ]
      }
    ]
  }
  //*/
};