import { connect } from 'react-redux'

import LoginPage from '../LoginPage'
import { login } from '../../actions/userActions'


const mapStateToProps = (state) => {
  const user = state.imarReducer.user
  return {
    userLogged: user.logged,
    userLogError: user.err
  }
}
export default connect(mapStateToProps, {
  login
})(LoginPage)
