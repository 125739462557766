// Inspired from:
//   demo: https://material-ui.com/getting-started/page-layout-examples/sign-in-side/
//   source: https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/page-layout-examples/sign-in-side

import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import GoogleLogin from 'react-google-login'

import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import {GOOGLE_SIGNIN_PUBLICKEY, GOOGLE_SIGNIN_PUBLICKEY_DEV} from '../constants/settings.js';

import ErrorSnackBar from './ErrorSnackBar'

import logoImageSRC from '../images/Astar600.png'

const responseGoogleFailure = (err) => {
  console.log('ERROR in LoginPage: ', err);
}

const LoginPage = ({ userLogged, userLogError, login }) => {

  const googlePublicKey = (process.env.NODE_ENV === 'development')?GOOGLE_SIGNIN_PUBLICKEY_DEV:GOOGLE_SIGNIN_PUBLICKEY
  const responseGoogleSuccess = (res) => {
    const authInfos = {
      tokenId: res['tokenId'],
      accessToken: res['accessToken'],
      googleId: res['googleId']
    }
    login(authInfos, res['profileObj']['name']);
  }

  return (
    userLogged ? (
      <Redirect to="/home" />
    ) : (
      <Grid container component="main" className="LoginPage">
        {
          // Display an error message if the login was wrong
          
          userLogError && (
            <div>
              <ErrorSnackBar message = "Invalid login" />
            </div>
          )
        }
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className="LoginPageLeftImage" />
        <Grid item xs={12} sm={8} md={5} elevation={6} square="true">
          <div className="LoginPageRight">

            <img src={logoImageSRC} className="LoginPageLogo"/>
            <Typography className="LoginPageDescription" component="div">
              Welcome to WebAR.rocks.image backoffice<br/>
              Update your images and organize them by AR layers here.

              <Divider className="LoginPageDivider" />
            </Typography>

            
            {/*<Avatar className="LoginPageAvatar">
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            */}

            <GoogleLogin
              className = "LoginPageGoogleButton"
              clientId = {googlePublicKey}
              buttonText = "Login with your google account"
              onSuccess = {responseGoogleSuccess}
              onFailure = {responseGoogleFailure}
              cookiePolicy = {'single_host_origin'}
            />
          </div>
        </Grid>
      </Grid>
    )
  );
};

LoginPage.propTypes = {
  userLogged: PropTypes.bool
};

export default LoginPage; 
