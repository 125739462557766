import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Button and add image button - see https://material-ui.com/components/buttons/
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// Use deletable chips to display layers - see https://material-ui.com/components/chips/
import Chip from '@material-ui/core/Chip';

// Alert dialog (delete layer confirmation) from https://material-ui.com/components/dialogs/#dialogs
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Layer selection:
import LayerSelector from './containers/LayerSelector';

// NN Selection:
// Selector from https://material-ui.com/components/selects/#simple-select
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import neuralNetworks from '../contrib/WebAR.rocks.image/neuralNets/listing.json';

// Parameters tuning:
import Slider from '@material-ui/core/Slider';

import {SERVER_WS_URL} from '../constants/settings.js';



// extract NNsDetect and NNsTracking:
const NNsDetect = [], NNsTracking = [];
let NNDetectDefault = {path: ''}, NNTrackingDefault = {path: ''};
const NNsByPath = {};

const extract_NNs = (neuralNets, NNCat, NNs, defaultVal) => {
  neuralNets.forEach((NN) => {
    const path = NNCat.path + '/' + NN.model;
    const NNProcessed = {
      path,
      label: NNCat.path + '/' + NN.model,
      thresholdDetectFactor: (typeof(NN.thresholdDetectFactor) === 'undefined') ? -1 : NN.thresholdDetectFactor
    };
    NNsByPath[path] = NNProcessed;
    NNs.push(NNProcessed);
    if (NN.default){
      defaultVal.path = NNProcessed.path;
    }
  });
}
neuralNetworks.categories.forEach((NNCat) => {
  extract_NNs(NNCat.neuralNetsDetect, NNCat, NNsDetect, NNDetectDefault);
  extract_NNs(NNCat.neuralNetsTracking, NNCat, NNsTracking, NNTrackingDefault);
});


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  fab: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none'
  },
  chip: {
    margin: theme.spacing(1),
  }
}));


const ARAppDetails = ({
  selectedApp,
  layers,
  deleteARApp, updateARApp
}) => {
  if (!selectedApp){
    return null;
  }

  const classes = useStyles();

  // get selected NNs:
  const selectedNNDetect = (selectedApp.NNDetect) ? selectedApp.NNDetect : NNDetectDefault.path;
  const selectedNNTracking = (selectedApp.NNTracking) ? selectedApp.NNTracking : NNTrackingDefault.path;
  const thresholdDetectFactor0 = (selectedApp.thresholdDetectFactor === -1) ? neuralNetworks.thresholdDetectFactor : selectedApp.thresholdDetectFactor;
  
  // component internal state:
  const [openDeleteAppDialog, setOpenDeleteAppDialog] = React.useState(false);
  const [editedAppLabel, setEditedAppLabel] = React.useState(selectedApp.label);
  const [editedAppURL, setEditedAppURL] = React.useState(selectedApp.url);
  const [editedAppID, setEditedAppID] = React.useState(selectedApp.id);
  const [editedAppLayers, setEditedAppLayers] = React.useState(selectedApp.layers.slice(0));
  const [editedAppNNDetect, setEditedAppNNDetect] = React.useState(selectedNNDetect);
  const [editedAppNNTracking, setEditedAppNNTracking] = React.useState(selectedNNTracking);
  const [editedAppDetectionThreshold, setEditedAppDetectionThreshold] = React.useState(thresholdDetectFactor0);

  if (editedAppID !== selectedApp.id){
    setEditedAppID(selectedApp.id)
    setEditedAppLabel(selectedApp.label)
    setEditedAppURL(selectedApp.url)
    setEditedAppLayers(selectedApp.layers)
    setEditedAppNNDetect(selectedNNDetect)
    setEditedAppNNTracking(selectedNNTracking)
    setEditedAppDetectionThreshold(selectedApp.thresholdDetectFactor)
  }

  // forge app test URL:
  const currentURLParsed = location.href.split('/');
  currentURLParsed.pop(); //remove page location
  const appTestURL = currentURLParsed.join('/') + '/testARApp/index.html?appId=' + selectedApp.id.toString()
                   + '&NNDetect=' + encodeURIComponent(selectedNNDetect)
                   + '&NNTracking=' + encodeURIComponent(selectedNNTracking)
                   + '&thres=' + encodeURIComponent(editedAppDetectionThreshold.toString())

  const appCode = ['const WEBARROCKSIMAGESETTINGS = {',
      '  appId: ' + selectedApp.id.toString() + ',',
      '  server: "' + SERVER_WS_URL + '",',
      '  thresholdDetectFactor: ' + editedAppDetectionThreshold.toString() + ',',
      '  NNDetect: "' + selectedNNDetect + '",',
      '  NNTracking: "' + selectedNNTracking + '"',
    '}'
  ];


  // CHANGE APP:
  const handleChangeLabel = (event) => {
    setEditedAppLabel(event.target.value);
  }
  const handleChangeURL = (event) => {
    setEditedAppURL(event.target.value);
  }
  const save = (newEditedAppLayers) => {
    updateARApp(selectedApp.id, editedAppLabel, editedAppURL,
                ( newEditedAppLayers ) ? newEditedAppLayers : editedAppLayers,
                editedAppNNDetect, editedAppNNTracking,
                editedAppDetectionThreshold);
  }

  // CHANGE PARAMETERS
  const handleChangeDetectionThreshold = (event, value) => {
    setEditedAppDetectionThreshold(value);
    save();
  }

  // DELETE APP:
  const handleClickDeleteApp = () => {
    setOpenDeleteAppDialog(true);
  }
  const handleCloseDeleteAppDialog = () => {
    setOpenDeleteAppDialog(false);
  }
  const handleDeleteApp = () => {
    handleCloseDeleteAppDialog();
    deleteARApp(selectedApp.id);
  }

  // LAYERS:
  const handleAddLayer = (layerID) => {
    const newEditedAppLayers = editedAppLayers.slice(0)
    newEditedAppLayers.push(layerID)
    console.log('ARAppDetails: handleAddLayer() layerID=', layerID, 'newEditedAppLayers=',newEditedAppLayers);
    setEditedAppLayers(newEditedAppLayers)
    save(newEditedAppLayers)
  }
  const handleDeleteLayer = (deletedLayerID) => {
    const newEditedAppLayers = editedAppLayers.filter((layerID) => {
      return layerID !== deletedLayerID
    })
    setEditedAppLayers(newEditedAppLayers)
    save(newEditedAppLayers)
  }

  const renderLayers = (layerIDs) => {
    return (
      <div>
        {
          layerIDs.map((layerID) => {
            const layer = layers.find((l) => {
              return l.id === layerID;
            })
            return (layer) ? (
              <Chip
                label={layer.label}
                onDelete={handleDeleteLayer.bind(null, layer.id)}
                className={classes.chip}
                key={layer.id}
              />) : null
          })
        }
      </div>
      )
  }


  // NN SELECTION:
  const renderNNSelector = (NNs, NNKey, defaultVal) => {
    const selectedNNPath = (NNKey === 'NNDetect') ? editedAppNNDetect : editedAppNNTracking;
    return (
      <FormControl className={classes.formControl}>
        <Select
          value={selectedNNPath}
          onChange={selectNN.bind(null, NNKey)}
          displayEmpty
          className="LayerSelectorSelect"
        >
           {
            NNs.map((NN) => {
              return (
                <MenuItem key={NN.path} value={NN.path}>{NN.label}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    )
  }
  const selectNN = (NNKey, event) => {
    const newNNPath = event.target.value;
    console.log('INFO in ARAppDetails: Update ', NNKey, ' to ', newNNPath);
    if (NNKey === "NNDetect"){
      const NN = NNsByPath[newNNPath];
      const thres = NN.thresholdDetectFactor;
      if (thres !== -1){
        setEditedAppDetectionThreshold(thres);
      }
      setEditedAppNNDetect(newNNPath);
    } else if (NNKey === "NNTracking") {
      setEditedAppNNTracking(newNNPath);
    }
  }
  React.useEffect(save, [editedAppNNTracking, editedAppNNDetect]);



  return (
    <div className = 'ARAppDetails'>
      <h2>AR app global settings:</h2>
      <TextField
        label="Name"
        value={editedAppLabel}
        margin="normal"
        onChange={handleChangeLabel}
        onBlur={save.bind(null, null)}
        variant="outlined"
      />

      <TextField
        label="URL"
        value={editedAppURL}
        margin="normal"
        onChange={handleChangeURL}
        onBlur={save.bind(null, null)}
        variant="outlined"
        className="ARAppDetailsURLField"
      />

      <Divider className="ARAppDetailsDivider" />
      <h2>Layers:</h2>

      <Typography component={'span'}>
      {
        (selectedApp.layers.length > 0) ?
        (
          <div className="ARAppDetailsNoticeAddLayer">
           These layers are included in the app: 
           {
            renderLayers(editedAppLayers)
           }

           <br/>Add a new layer:
          </div>
        ) : (
          <div className="ARAppDetailsNoticeAddLayer">
            There is no layers in this app. Click on the layer you want to add:
          </div>
        )
      }

      <div className="ARAppDetailsAddLayerSelector">
        <LayerSelector onSelectLayer={handleAddLayer} blackListIDs={editedAppLayers} />
      </div>
      </Typography>
          

      <Divider className="ARAppDetailsDivider" />
      <h2>Parameters:</h2>
      <Typography id="discrete-slider-always" gutterBottom>
        Detection threshold:
      </Typography>
      <Slider
        value={editedAppDetectionThreshold}
        aria-labelledby="discrete-slider-always"
        min={0}
        max={1.2}
        step={0.01}
        onChange={handleChangeDetectionThreshold}
        marks={[
          {
            value: 0,
            label: '0.0'
          },
          {
            value: 0.5,
            label: '0.5'
          },
          {
            value: 1,
            label: '1.0'
          },{
            value: 1.2,
            label: '1.2'
          }]}
        valueLabelDisplay="on"
      />


      <Divider className="ARAppDetailsDivider" />
      <h2>Neuron networks:</h2>

      <Typography>Detection neural network, i.e. <code>NNDetect</code>:</Typography>
        { renderNNSelector(NNsDetect, 'NNDetect', NNDetectDefault) }<br/>

      <br/>
      <Typography>Tracking neural network, i.e. <code>NNTracking</code>:</Typography>
        { renderNNSelector(NNsTracking, 'NNTracking', NNTrackingDefault) }
      


      <Divider className="ARAppDetailsDivider" />
      <h2>Test AR App:</h2>
      
      <Typography>
        You can test the AR App at this URL: <br/><br/><Link href={appTestURL} target='_blank'>{appTestURL}</Link>
      </Typography>

      <br/>

      
      <Divider className="ARAppDetailsDivider" />
      <h2>Build AR App:</h2>
      <Typography>
        You can build you own AR App by implementing complex 3D experiences using <Link href='https://github.com/xavierjs/WebAR.rocks.image' target='_blank'>WebAR.rocks.image Github repository</Link>.
      </Typography>
      <Typography>
        We recommend using <Link href='https://threejs.org' target='_blank'>THREE.js</Link> as 3D engine like in the example provided in the Github repository (<i>Basic demo</i>).
      </Typography>

      <br/>

      <Typography>To get the same app as the test link, clone the Github repository. Then copy and paste the code above in 
      <Link href='https://github.com/xavierjs/WebAR.rocks.image/blob/master/demos/basic/main.js' target='_blank'>/demos/basic/main.js</Link>.
      Host the full <Link href='https://github.com/xavierjs/WebAR.rocks.image' target='_blank'>WebAR.rocks.image Github repository</Link> with a static HTTPs server and open &nbsp;
       <Link href='https://github.com/xavierjs/WebAR.rocks.image/blob/master/demos/basic/index.html' target='_blank'>demos/basic/index.html</Link>.
      </Typography>

      <br/>
      <TextField
          id="outlined-multiline-static"
          label="Copy paste this code"
          multiline
          fullWidth
          rows={appCode.length}
          value={appCode.join('\n')}
          variant="outlined"
          className="ARAppDetailsTextFieldCode"
        />

      <Divider className="ARAppDetailsDivider" />

      <div className = "ARAppDetailsButtonsContainer">
        <Button color="secondary" variant="contained" className="ARAppDetailsButton" onClick={handleClickDeleteApp} >Delete AR app</Button>
      </div>


      <Dialog
        open={openDeleteAppDialog}
        onClose={handleCloseDeleteAppDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete AR app confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this AR app?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteAppDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteApp} color="primary" autoFocus>
            Delete AR App
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
};

export default ARAppDetails; 
