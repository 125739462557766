import { SERVER_BO_URL, SERVER_BO_URL_DEV } from '../constants/settings.js'


export function fetchHelper(urlPath, data){
  const urlServer = (process.env.NODE_ENV === 'development') ? SERVER_BO_URL_DEV : SERVER_BO_URL
  const url = urlServer + urlPath;
  
  let body = null;
  try {
    body = JSON.stringify(data);
  } catch(err){
    console.log('ERROR in helpers.js - fetchHelper: cannot serialize data. Err = ', err);
    console.log('data =', data);
    debugger;
  }

  return fetch(url, {
    method: 'POST',
    credentials: 'include', // to send cookies with the request
    body,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(function(response) {
    return response.json()
  });
}
