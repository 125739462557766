import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_LOGERR,

  UPDATE_ARAPPS,
  SELECT_ARAPP,

  UPDATE_LAYERS,
  SELECT_LAYER,

  UPDATE_IMAGES
} from '../constants/actionTypes';


//import {necessaryDataIsProvidedToCalculateSavings, calculateSavings} from '../utils/fuelSavings';

import objectAssign from 'object-assign';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function imarReducer(state = initialState.imar, action) {
  
  switch (action.type) {

    // USER MANAGEMENT REDUCERS:
    case USER_LOGIN:
      return Object.assign({}, state,
        {
          user: {
            id: action.userId,
            googleId: action. userGoogleId,
            name: action.given_name,
            fullName: action.name,
            email: action.email,
            picture: action.picture,
            logged: true,
            err: false
          },
          selectedAppId: -1,
          selectedLayerId: -1,
          layers: action.layers,
          apps: action.apps
        });

    case USER_LOGERR:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          logged: false,
          err: true
        })
      })

    case USER_LOGOUT: //reset state to initial state
      return Object.assign({}, initialState.imar)


    // LAYERS MANAGEMENT REDUCERS:
    case UPDATE_LAYERS:
      let selectedLayerId = -1;
      if (state.selectedLayerId !==-1 && action.layers.some((layer) => {
        return layer.id === state.selectedLayerId;
      })){
        selectedLayerId = state.selectedLayerId;
      }
      const newLayers = action.layers.map((newLayer) => {
        const previousLayer = state.layers.find((previousLayer) => {
          return newLayer.id === previousLayer.id;
        });
        if (!previousLayer){
          return Object.assign({imagesLoaded: false}, newLayer);
        }
        return Object.assign({}, newLayer, {images: previousLayer.images, imagesLoaded: true});
      })
      return Object.assign({}, state, {
        layers: newLayers
      }, {
        selectedLayerId
      })

    case SELECT_LAYER:
      return Object.assign({}, state, {
        selectedLayerId: action.layerID
      })

    // ARAPPS MANAGEMENT REDUCERS:
    case UPDATE_ARAPPS:
      return Object.assign({}, state, {
        apps: action.apps
      })

    case SELECT_ARAPP:
      return Object.assign({}, state, {
        selectedAppId: action.appID
      })
    
    // IMAGES MANAGEMENT REDUCERS:
    case UPDATE_IMAGES:
      const layers = state.layers.map((layer) => {
        if (layer.id !== action.layer){
          return layer; //unchanged layer
        }
        if (action.imChangedID !== -1){
          action.images.forEach((newImg) => {
            if (!newImg.url) {
              return
            }
            const previousImage = layer.images.find((img) => { return img.id === newImg.id })
            if (!previousImage || !previousImage.url){
              return
            }
            const previousImageParsed = previousImage.url.split('#')
            const previousImageParsedAnchor = (previousImageParsed.length === 1) ? 0 : parseInt(previousImageParsed.pop())
            const imageAnchor = (newImg.id === action.imChangedID) ? previousImageParsedAnchor+1 : previousImageParsedAnchor
            newImg.url += '#' + imageAnchor.toString()
          })
        }
        if (action.images){
          return Object.assign({}, layer, {images: action.images, imagesLoaded: true})
        } else {
          return layer;
        }
      })
      //console.log(action, layers, state.layers); debugger;
      console.log('UPDATE_IMAGES: layers =', layers)
      return Object.assign({}, state, { layers })

    default:
      return state;
  }
}