import { connect } from 'react-redux'
import ARAppsPage from '../ARAppsPage'
import { addARApp } from '../../actions/ARAppActions'


const mapStateToProps = (state) => {
  return {
    userLogged: state.imarReducer.user.logged,
    appSelected: (state.imarReducer.selectedAppId && state.imarReducer.selectedAppId !== -1),
    apps: state.imarReducer.apps
  }
}

export default connect(mapStateToProps,{
  addARApp
})(ARAppsPage)