import {
  UPDATE_IMAGES
} from '../constants/actionTypes'
import {
  PHASH_TYPE,
  PHASH_LENGTH,
  PHASH_N,
  THUMBNAIL_AREA
} from '../constants/settings'
import {
  logout
} from './userActions'
import { fetchHelper } from '../helpers/helpers'
import DCT from '../helpers/DCT' // Library to compute the pHash using Direct Cosine Transform
 

DCT.init({
  hashType: PHASH_TYPE,
  hashLength: PHASH_LENGTH,
  N: PHASH_N
})


function build_thumbnail(img){
  const cv = document.createElement('canvas')
  const imw = img['width']
  const imh = img['height']
  const imgArea =imw * imh
  const scale = Math.sqrt(THUMBNAIL_AREA / imgArea)
  const cvw = Math.round(imw * scale)
  const cvh = Math.round(imh * scale)
  cv.width = cvw
  cv.height = cvh
  const ctx = cv.getContext('2d')
  ctx.drawImage(img, 0,0, cvw, cvh)

  /*console.log('DEBUG IN imageActions.js - build_thumbnail: append the CV to the DOM')
  document.body.appendChild(cv)
  cv.style.position = "absolute"
  cv.style.top = "0px"
  cv.style.left = "0px"
  //*/

  return cv
}

function compute_imageParams(layerID, imageID, img, label){

  return new Promise((accept, reject) => {
    //build thumbnail
    const thumbnail = (img) ? build_thumbnail(img) : null

    //compute pHash
    const pHashTyped = DCT.compute(img);

    //convert typed array to standard array - see https://stackoverflow.com/questions/12760643/how-to-convert-a-javascript-typed-array-into-a-javascript-array
    const pHash = Array.prototype.slice.call(pHashTyped);
    
    accept ({
      label,
      layer: layerID,
      imageID,
      img: (img)?thumbnail.toDataURL('image/png'):null,
      pHash,
      pHashType: PHASH_TYPE
    })
  })
}


function processAnswer(dispatch, answer, imChangedID){
   if (answer['success']){
     dispatch({
       type: UPDATE_IMAGES,
       imChangedID,
       ...answer
     })
   } else if(answer['error'] && answer['error'] === 'INVALID USER') {
     dispatch(logout())
   }
}


export function addImage(layerID, img, label){
  
  return (dispatch) => {
    compute_imageParams(layerID, -1, img, label).then((imageParams) =>{

      fetchHelper('/addImage', imageParams).then((answer) => {
         processAnswer(dispatch, answer, -1)
      }).catch((err) => {
        console.log('WARNING: cannot addImage because ' + err.message)
      })

    }).catch((err) => {
      throw new Error('Cannot compute image parameters ' + err.message)
    })
  }
  
}


export function deleteImage(imageID){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/deleteImage', { imageID }).then((answer) => {
      processAnswer(dispatch, answer, -1)
    }).catch((err) => {
      console.log('WARNING: cannot deleteImage because ' + err.message)
    })
  }
  
}


export function updateImage(imageID, img, label){
  
  return (dispatch) => {
    compute_imageParams(-1, imageID, img, label).then((imageParams) =>{

      fetchHelper('/updateImage', imageParams).then((answer) => {
        // alter answer to tweak imageURL to avoid cache problems if the user change the image
        processAnswer(dispatch, answer, imageID)
      }).catch((err) => {
        console.log('WARNING: cannot updateImage because ' + err.message)
      })

    }).catch((err) => {
      throw new Error('Cannot compute image parameters' + err.message)
    })
  }

}