import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { Redirect } from 'react-router';

import NavBar from './containers/NavBar';
import Welcome from './containers/Welcome';


const HomePage = ({ userLogged }) => {
  return (
    (!userLogged) ? (
      <Redirect to="/login" />
    ) : (
      <div>
       <NavBar />
       <div>
        <Welcome />
       </div>
      </div>
    ));
};

HomePage.propTypes = {
  userLogged: PropTypes.bool
};

export default HomePage; 
