import { connect } from 'react-redux'
import ARAppDetails from '../ARAppDetails'
import { deleteARApp, updateARApp } from '../../actions/ARAppActions'

const mapStateToProps = (state) => {
  let selectedApp = null;
  if (state.imarReducer.selectedAppId !== -1){
    selectedApp = state.imarReducer.apps.find((app) => {
      return app.id === state.imarReducer.selectedAppId;
    })
  }
  return {
    layers: state.imarReducer.layers,
    selectedApp
  }
}

export default connect(mapStateToProps, {
  deleteARApp, updateARApp
})(ARAppDetails)