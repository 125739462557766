// Used for in-form layer selection
// NOT for the layer menu of the layer tab

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'


// Selector from https://material-ui.com/components/selects/#simple-select
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}))


export default function LayerSelector({layers, onSelectLayer, blackListIDs}) {
  const classes = useStyles()
  
  // internal state:
  const [selectedLayerID, setSelectedLayerID] = React.useState(-1)

  const handleChange = (event) => {
    const layerID = event.target.value
    setSelectedLayerID(layerID)
    onSelectLayer(layerID)
  }

  const selectableLayers = layers.filter((layer) => {
    if (blackListIDs){
      if (blackListIDs.indexOf(layer.id) !== -1){
        return false
      }
    }
    return true
  })

  return (
    <form className={classes.root, "LayerSelector"} autoComplete="off">
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="layerID">Select layer</InputLabel>
        <Select
          value={selectedLayerID}
          onChange={handleChange}
          displayEmpty
          className="LayerSelectorSelect"
        >
           {
            (selectableLayers.length === 0)? (
              <MenuItem value="" disabled>
                No layer available  
              </MenuItem>
            ) : selectableLayers.map((layer) => {
              return (
                <MenuItem key={layer.id} value={layer.id}>{layer.label}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </form>
  )
}