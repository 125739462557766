import {
  UPDATE_LAYERS,
  UPDATE_IMAGES,
  SELECT_LAYER,
} from '../constants/actionTypes'
import {
  logout
} from './userActions'

import { fetchHelper } from '../helpers/helpers'


const processAnswer = (dispatch, answer) => {
  if (answer['success']){
    dispatch({
      type: UPDATE_LAYERS,
      ...answer
    })
  } else if(answer['error'] && answer['error'] === 'INVALID USER') {
    dispatch(logout())
  }
}



export function readImages(layerID){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/readImages', { layerID }).then((answer) => {
        if (answer['success']){
          dispatch({
            type: UPDATE_IMAGES,
            layer: layerID,
            ...answer
          })
        } else if(answer['error'] && answer['error'] === 'INVALID USER') {
          dispatch(logout())
        }
    }).catch((err) => {
      console.log('WARNING: cannot readImages because ' + err.message)
    })
  }
  
}

export function addLayer(label){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/addLayer', { label }).then((answer) => {
      processAnswer(dispatch, answer)
      if (answer['success']){
        dispatch(selectLayer(answer['newLayerID']))
      }
    }).catch((err) => {
      console.log('WARNING: cannot addLayer because ', err)
    })
  }
  
}


export function deleteLayer(layerID){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/deleteLayer', { id: layerID }).then((answer) => {
      processAnswer(dispatch, answer)
      if (answer['success']){
        dispatch(selectLayer(-1))
      }
    }).catch((err) => {
      console.log('WARNING: cannot deleteLayer because ', err)
    })
  }
  
}


export function updateLayer(layerID, label){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/updateLayer', { id: layerID, label }).then((answer) => {
      processAnswer(dispatch, answer)
    }).catch((err) => {
      console.log('WARNING: cannot updateLayer because ', err)
    })
  }
  
}


export function selectLayer(layerID){
  console.log('INFO in layerActions.js: SELECT LAYER with layerID = ', layerID)
  return (dispatch) => {
    dispatch(readImages(layerID));
    dispatch({
      type: SELECT_LAYER,
      layerID
    })
  }
}