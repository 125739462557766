import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Button and add image button - see https://material-ui.com/components/buttons/
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// Expansion panel, to display the images:
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Alert dialog (delete layer confirmation) from https://material-ui.com/components/dialogs/#dialogs
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Linear progress bar for loading from https://material-ui.com/components/progress/#progress
import LinearProgress from '@material-ui/core/LinearProgress';

//import defaultImageURL from '../images/defaultImage.png';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  fab: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none'
  }
}));


const ARLayerDetails = ({
  selectedLayer,
  deleteLayer, updateLayer,
  deleteImage, addImage, updateImage
}) => {
  const classes = useStyles();

  // component internal state:
  const [openDeleteLayerDialog, setOpenDeleteLayerDialog] = React.useState(false);
  const [openDeleteImageDialog, setOpenDeleteImageDialog] = React.useState(false);
  const [editedLayerLabel, setEditedLayerLabel] = React.useState(selectedLayer.label);
  const [editedLayerID, setEditedLayerID] = React.useState(selectedLayer.id);
  const [deleteImageID, setDeleteImageID] =  React.useState(-1);
  const [editedImageLabels, setEditedImageLabels] = React.useState(null);
  
  if (editedLayerID !== selectedLayer.id){
    setEditedLayerID(selectedLayer.id)
    setEditedLayerLabel(selectedLayer.label)
    setEditedImageLabels(null)
  }

  if ( (editedImageLabels === null && selectedLayer.imagesLoaded)
    || (editedImageLabels !== null && Object.keys(editedImageLabels).length !== selectedLayer.images.length) ){
    console.log('INFO in ARLayerDetails: reset editedImageLabels')
    const eil = {}
    selectedLayer.images.forEach((img) => {
      eil[img.id] = img.label;
    })
    setEditedImageLabels(eil)
  }

  
  // CHANGE LAYER:
  const handleChangeLabel = (event) => {
    setEditedLayerLabel(event.target.value);
  }
  const save = () => {
    updateLayer(selectedLayer.id, editedLayerLabel);
  }

  // DELETE LAYER:
  const handleClickDeleteLayer = () => {
    setOpenDeleteLayerDialog(true);
  }
  const handleCloseDeleteLayerDialog = () => {
    setOpenDeleteLayerDialog(false);
  }
  const handleDeleteLayer = () => {
    handleCloseDeleteLayerDialog();
    deleteLayer(selectedLayer.id);
  }

  // ADD IMAGE:
  const handleAddImage = () => {
    addImage(selectedLayer.id, null, 'NEWIMAGE');
  }

  // CHANGE IMAGE:
  const handleChangeImageLabel = (imgID, event) => {
    const zou = {}
    zou[imgID] = event.target.value
    const newImageLabels = Object.assign({}, editedImageLabels, zou)
    setEditedImageLabels(newImageLabels)
  }
  const saveImage = (imgID, img) => {
    updateImage(imgID, img, editedImageLabels[imgID])
  }
  const handleImageChange = (imgID, event) => {
    const input = event.target
    if (!input.files || !input.files[0]) {
      return
    }
    const reader = new FileReader()
    reader.onload = function (e) {
      const imageDataURL = e.target.result
      const img = new Image()
      img.src = imageDataURL
      img.onload = saveImage.bind(null, imgID, img)
    }
    reader.readAsDataURL(input.files[0])
  }

  // DELETE IMAGE:
  const handleClickDeleteImage = (imgID) => {
    setOpenDeleteImageDialog(true);
    setDeleteImageID(imgID);
  }
  const handleCloseDeleteImageDialog = () => {
    setOpenDeleteImageDialog(false);
    setDeleteImageID(-1);
  }
  const handleDeleteImage = () => {
    deleteImage(deleteImageID);
    handleCloseDeleteImageDialog();
  }

  const renderImage = (img, imgIndex) => {
    // draw an expansion panel - see https://material-ui.com/components/expansion-panels/
    const imgIdStr = img.id.toString()
    const uploadButtonId = "contained-button-file" + imgIdStr

    return (
      <ExpansionPanel key={img.id}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={"panel1a-header" + imgIdStr}
        >
        <Typography className={classes.heading}>{img.label}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          
          <div>
            <TextField
              label="Label"
              value={editedImageLabels[img.id]}
              margin="normal"
              onChange={handleChangeImageLabel.bind(null, img.id)}
              onBlur={saveImage.bind(null, img.id, null)}
              variant="outlined"
            />
          </div>

          <div className="ARLayerDetailsChangeImage">
            <input
              accept="image/*"
              className={classes.input}
              id={uploadButtonId}
              multiple
              type="file"
              onChange={handleImageChange.bind(null, img.id)}
            />
            {
              (img.url === '')
              ? (
                  <label htmlFor={uploadButtonId}>
                    <Button component="span" color="primary">Upload an image</Button>
                  </label>
                ) : (
                  <div className="ARLayerDetailsImageDisplayContainer">
                    <div className="ARLayerDetailsImageDisplay">
                      <img src={img.url} />
                    </div>
                    <label htmlFor={uploadButtonId}>
                      <Button component="span" color="primary">Change image</Button>
                    </label>
                  </div>
                )
            }
          </div>

          <div className="ARLayerDetailsDeleteImage">
            <Button onClick={handleClickDeleteImage.bind(null, img.id)} color="primary" autoFocus>
              Delete image target
            </Button>
          </div>
          
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  } //end renderImage()

  const renderImages = () => {
    return (selectedLayer.images.length) ?
      (
        <div>
        {
          selectedLayer.images.map(renderImage)
        }
        <Fab color="primary" aria-label="add" className="ARLayersDetailsAddFloatButton" onClick={handleAddImage}>
          <AddIcon />
        </Fab>
        </div>
      ) : (
        <Typography>
          There is no image target in this layer yet. <Button onClick={handleAddImage}>Add a new image target</Button>
        </Typography>
      )
  } //end renderImages()

  return (
    <div className = 'ARLayerDetails'>
      <h2>AR Layer settings:</h2>
      <TextField
        label="Name"
        value={editedLayerLabel}
        margin="normal"
        onChange={handleChangeLabel}
        onBlur={save}
        variant="outlined"
      />

      <Divider className="ARLayerDetailsDivider" />
      <h2>Image targets:</h2>

      {
        (selectedLayer.imagesLoaded && editedImageLabels !== null) ? renderImages() : ( <LinearProgress /> )
      }

      <Divider className="ARLayerDetailsDivider" />

      <div className = "ARLayerDetailsButtonsContainer">
        {/*<Button color="primary" variant="contained" className="ARLayerDetailsButton" onClick={handleClickSave} >Save changes</Button>*/}
        <Button color="secondary" variant="contained" className="ARLayerDetailsButton" onClick={handleClickDeleteLayer} >Delete layer</Button>
      </div>



      <Dialog
        open={openDeleteLayerDialog}
        onClose={handleCloseDeleteLayerDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete layer confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this layer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteLayerDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteLayer} color="primary" autoFocus>
            Delete AR Layer
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDeleteImageDialog}
        onClose={handleCloseDeleteImageDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete image target confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this image target?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteImageDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteImage} color="primary" autoFocus>
            Delete Image
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default ARLayerDetails; 
