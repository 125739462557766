// Set up your root reducer here...
import { combineReducers } from 'redux';
import imarReducer from './imarReducer';
import { connectRouter } from 'connected-react-router'

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  imarReducer,
});

export default rootReducer;