// user management:
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGERR = 'USER_LOGERR'

// AR app management:
export const UPDATE_ARAPPS = 'UPDATE_ARAPPS'
export const SELECT_ARAPP = 'SELECT_ARAPP'

// AR layer management
export const UPDATE_LAYERS = 'UPDATE_LAYERS'
export const SELECT_LAYER = 'SELECT_LAYER'

// Image management
export const UPDATE_IMAGES = 'UPDATE_IMAGES'
export const SELECT_IMAGE = 'SELECT_IMAGE'

export const DO_NOTHING = 'DO_NOTHING'