import { connect } from 'react-redux'
import ARLayerDetails from '../ARLayerDetails'
import { deleteLayer, updateLayer } from '../../actions/layerActions'
import { deleteImage, addImage, updateImage } from '../../actions/imageActions'

const mapStateToProps = (state) => {
  let selectedLayer = null;
  if (state.imarReducer.selectedLayerId !== -1){
    selectedLayer = state.imarReducer.layers.find((layer) => {
      return layer.id === state.imarReducer.selectedLayerId;
    })
  }
  return {
    selectedLayer
  }
}

export default connect(mapStateToProps, {
  deleteLayer, updateLayer,
  deleteImage, addImage, updateImage
})(ARLayerDetails)