import { connect } from 'react-redux'

import NavBar from '../NavBar'
import { logout } from '../../actions/userActions'

const mapStateToProps = (state) => {
  return {
    userName: state.imarReducer.user.name
  }
}
export default connect(mapStateToProps, {
  logout
})(NavBar)
