import React from 'react'
import {withRouter} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

// User menu (on the right):
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

// Tabs - see https://material-ui.com/components/tabs/#tabs
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const NavBar = ({
    logout, redirect, // actions
    userName,         // properties bound to the app state
    location, history // provided by react router
  }) => {
  const classes = useStyles();

  //console.log(logout);
  // User menu state:
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const openUser = Boolean(anchorElUser)
  
  const tabRoutes = ['/home', '/apps', '/layers']
  let currentTabIndex = tabRoutes.indexOf(location.pathname)
  if (currentTabIndex === -1){
    currentTabIndex = 0;
  }

  // Main menu state:
  const [tabValue, setTabValue] = React.useState(currentTabIndex)

  function handleMenuUser(event) {
    setAnchorElUser(event.currentTarget)
  }

  function handleClose() {
    setAnchorElUser(null)
  }

  function handleLogout() {
    handleClose()
    logout()
  }

  function handleRedirect(url){
    history.push(url)
  }

  function handleTabChange(event, newValue){
    setTabValue(newValue);
    handleRedirect(tabRoutes[newValue])
  }


  const currentLocation = location.pathname;

  return (
    <div className="NavBarAppBarContainer">
      <AppBar position="static" className="NavBarAppBar">
        
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="menu-tabs">
          <Tab component={'span'} label="Home" />
          <Tab component={'span'} label="Apps" />
          <Tab component={'span'} label="AR Layers" />
        </Tabs>
        
        <div style={{flexGrow: 1}} />
     
        <div>
          <IconButton
            aria-label="Account of current user"
            aria-controls="userMenu-appbar"
            aria-haspopup="true"
            onClick={handleMenuUser}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="userMenu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openUser}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout { userName }</MenuItem>
          </Menu>
        </div>
        
      </AppBar>
    </div>
  );
}

export default withRouter(NavBar)