/*
  compute the Direct Cosine Transform pHash of an image

  spec:
  - <int> N: dimension in pixels of the square image where DCT is proceeded. Default: 128
  - <int> hashLength: hash length (sould be < N*N). Default: 256. It should be a multiple of 32
  - <int> hashType: ENUM, 0 (default) -> greyscale hash, 1 -> RGB hash 

 */
const DCT = (function(){
  let _N = 128, _hashLength = 256, _M, _tM, _DCT, _MD, _Nh, _DCTh, _DCTMedian;
  const _workingCanvas = [null, null];
  let _monocolorIData = new Float32Array();
  let _hashType = 0;

  const createMatrix = function(n){
    return new Float32Array(n*n);
  }
  
  const createWorkingCanvas = function(){
    const cv = document.createElement('canvas');
    cv['width'] = _N;
    cv['height'] = _N; 
    return { cv: cv, ctx: cv.getContext('2d')};
  }

  const sizeWorkingCanvas = function(cv, w, h){
    if (cv['width'] < w){
      cv['width'] = w;
    }
    if (cv['height'] < h){
      cv['height'] = h;
    }
  }

  const computeDCTMatrix = function(N){
    //compute the DCT Matrix
    const M = createMatrix(N);
    const Mi0 = 1.0/Math.sqrt(N);
    const A = Math.sqrt(2.0/N);
    const B = Math.PI / (2.0*N);
    for (let i=0; i<N; ++i){
      for (let j=0; j<N; ++j){
        M[i*N + j] = (i===0)? Mi0 : A*Math.cos(B*i*(2*j+1));
      }
    }
    return M;
  }

  const transposeMatrix = function(M, N){
    const tM = createMatrix(N);
    for (let i=0; i<N; ++i){
      for (let j=0; j<N; ++j){
        tM[j*N + i] = M[i*N + j];
      }
    }
    return tM;
  }

  const computeMonoColorIData = function(rgbaIData, monocolorIData, n, luma){
    const nPix = n*n;
    const gamma = 2.2;
    for (let i=0; i<nPix; ++i){
      const redLinear =   Math.pow(rgbaIData[4*i  ]/255.0, gamma);
      const greenLinear = Math.pow(rgbaIData[4*i+1]/255.0, gamma);
      const blueLinear =  Math.pow(rgbaIData[4*i+2]/255.0, gamma);
      
      //each values are signed normalized:
      monocolorIData[i] = 2.0*(redLinear*luma[0] + greenLinear*luma[1] + blueLinear*luma[2]) - 1.0;
    }
  }

  //do R = A*B
  const multMatrices = function(A,B,R,N){
    for (let i=0; i<N; ++i){
      for (let j=0; j<N; ++j){
        let v = 0.0;
        for (let k=0; k<N; ++k){
          v += A[i*N + k] * B[k*N + j];
        }
        R[i*N + j] = v;
      }
    }
  }

  //crop upper left part of matrix A and put result to B:
  const cropMatrix = function(A,B,Na,Nb){
    for (let i=0; i<Nb; ++i){
      for (let j=0; j<Nb; ++j){
        B[i*Nb + j] = A[i*Na + j];
      }
    }
  }

  //compute median coefficients:
  const computeMedians = function(M, Mmeds, N){
    for (let i=0; i<N; ++i){
      for (let j=0; j<N; ++j){
        const p = i*N + j;

        //build the neighbors array
        const vals = [M[p]];
        if (i>=1 && j>=1) vals.push(M[p-N-1]);
        if (i>=1) vals.push(M[p-N]);
        if (i>=1 && j<=N-2) vals.push(M[p-N+1]);

        if (i<=N-2 && j>=1) vals.push(M[p+N-1]);
        if (i<=N-2) vals.push(M[p+N]);
        if (i<=N-2 && j<=N-2) vals.push(M[p+N+1]);
        
        if (j>=1) vals.push(M[p-1]);
        if (j<=N-2) vals.push(M[p+1]);

        vals.sort();
        Mmeds[p] = vals[Math.floor(vals.length/2.0)];
      }
    }
  }

  const computeHash = function(DCT, DCTMedians, N, hashLength) {
    const h32 = new Uint32Array(hashLength/32);
    let i=0, j=0, n32=0, v32=0, nBits=0;
    for (let k=0; k<hashLength; ++k){
      const bit = (DCT[i*N + j]>=DCTMedians[i*N + j])?1:0;
      --i, ++j;
      if (i < 0){
        i = j;
        j = 0;
      }
      v32 += bit << nBits;
      if (++nBits === 32){
        h32[n32++] = v32;
        v32 = 0, nBits = 0;
      }
    }
    return h32;
  }

  const computeMonoColorHash = function(reducedImageData, luma, hashLength){
    computeMonoColorIData(reducedImageData, _monocolorIData, _N, luma);
    //compute M*DCT (intermediary result):
    multMatrices(_M, _monocolorIData, _MD, _N);
    //compute DCT:
    multMatrices(_MD, _tM, _DCT, _N);
    //crop _DCT to compute the median filter only on useful values
    cropMatrix(_DCT, _DCTh, _N, _Nh);
    computeMedians(_DCTh, _DCTMedian, _Nh);
    return computeHash(_DCTh, _DCTMedian, _Nh, hashLength);
  }

  const hammingDistance = function(x, y) {
    let i = x ^ y;
    i = i - ((i >> 1) & 0x55555555);
    i = (i & 0x33333333) + ((i >> 2) & 0x33333333);
    i = (i + (i >> 4)) & 0x0f0f0f0f;
    i = i + (i >> 8);
    i = i + (i >> 16);
    return i & 0x3f;
  }

  const int32ToBinString = function(n){
    return n.toString(2).padStart(32, '0');
  }

  const that = {
    init: function(spec){
      if (spec.N) _N = spec.N;
      if (spec.hashLength) _hashLength = spec.hashLength;
      if (spec.hashType) _hashType = spec.hashType;
      if (_hashLength%32 !== 0) {
        throw new Error('Invalid hashLength: should be a multiple of 32');
      }
      
      _workingCanvas[0] = createWorkingCanvas();
      _workingCanvas[1] = createWorkingCanvas();
      _monocolorIData = createMatrix(_N);

      _M = computeDCTMatrix(_N);
      _tM = transposeMatrix(_M, _N);
      _DCT = createMatrix(_N);
      _MD = createMatrix(_N);

      _Nh = Math.ceil(Math.sqrt(2*_hashLength) + 1);
      _Nh = Math.min(_Nh, _N);
      _DCTh = createMatrix(_Nh);
      _DCTMedian = createMatrix(_Nh);
    },

    reduceImage: function(imageOrCanvas, N){
      //reduce successively the image to N*N pixels
      const w = imageOrCanvas['width'];
      const h = imageOrCanvas['height'];

      sizeWorkingCanvas(_workingCanvas[0].cv, w,h);
      sizeWorkingCanvas(_workingCanvas[1].cv, w,h);

      const log2wN = Math.log2(w/N);
      const log2hN = Math.log2(h/N);

      const nIterations = Math.ceil(Math.max(log2wN, log2hN));
      let sWidth = imageOrCanvas['width'];
      let sHeight = imageOrCanvas['height'];

      // reduce successively the image to N*N pixels and keep the same reduction ratio between 2 steps:
      for (let i=nIterations; i>=0; --i){
        const iNorm = i/nIterations;
        const dWidth = Math.round(N*Math.pow(2, iNorm * log2wN));
        const dHeight = Math.round(N*Math.pow(2, iNorm * log2hN));

        const src = (i===nIterations)?imageOrCanvas:_workingCanvas[1].cv;
        _workingCanvas[0].ctx.drawImage(src,
          0,0, //sx, sy
          sWidth, sHeight,//sWidth, sHeight
          0,0,//dx, dy
          dWidth, dHeight//dWidth, dHeight
          );

        sWidth = dWidth, sHeight = dHeight;
        _workingCanvas.reverse(); //swap workingCanvas
      }

      const iData = _workingCanvas[1].ctx.getImageData(0,0,N,N);
      return iData.data;
    },

    compute: function(imageOrCanvas){
      if (!imageOrCanvas){
        //return empty hash
        return new Uint32Array(_hashLength/32);
      }

      const reducedImageData = that.reduceImage(imageOrCanvas, _N);

      let pHash = null;

      switch(_hashType){
        case 0:
          pHash = computeMonoColorHash(reducedImageData, [0.2126, 0.7152, 0.0722], _hashLength);
          break;
        case 1:
          const h3 = Math.floor(_hashLength/3);
          const pHashR = computeMonoColorHash(reducedImageData, [1, 0, 0], h3);
          const pHashG = computeMonoColorHash(reducedImageData, [0, 1, 0], h3);
          const pHashB = computeMonoColorHash(reducedImageData, [0, 0, 1], h3);
          const h32 = _hashLength/(32*3);
          pHash = new Uint32Array(h32 * 3);
          for (let i=0; i<h32; ++i){
            pHash[3*i] = pHashR[i];
            pHash[3*i + 1] = pHashG[i];
            pHash[3*i + 2] = pHashB[i];
          }
          break;
      }

      return pHash;
    },

    computeHammingDistance: function(hash0, hash1){
      let hammingDist = 0;
      hash0.forEach(function(hashChunk, chunkIndex){
        hammingDist += hammingDistance(hashChunk, hash1[chunkIndex]);
      });
      return hammingDist;
    },

    hashToString: function(hash){
      const hashChunksStrings = [];
      hash.forEach(function(hashChunk){
        hashChunksStrings.push(int32ToBinString(hashChunk));
      });
      return hashChunksStrings.join(' ');
    },

    //see research handwritten notes from 2019-05-06
    //return k, threshold of hamming distance so that P( d(X,A)<k ) = epsilon
    //this is not an exact value
    computeThreshold: function(eps){
      const n = _N;
      const a = Math.log(eps) + 0.5*Math.log(0.5*n*Math.PI);
      return Math.floor((n/2.0) - 0.5*Math.sqrt(-2*n*a));
    },

    areIdentical: function(hash0, hash1, eps){
      const threshold = that.computeThreshold(eps);
      return (that.computeHammingDistance(hash0, hash1)<threshold);
    }
  };
  return that;
})();

export default DCT;