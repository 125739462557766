import { connect } from 'react-redux'
import ARLayersPage from '../ARLayersPage'
import { addLayer } from '../../actions/layerActions'

const mapStateToProps = (state) => {
  return {
    userLogged: state.imarReducer.user.logged,
    layerSelected: (state.imarReducer.selectedLayerId && state.imarReducer.selectedLayerId !== -1),
    layers: state.imarReducer.layers
  }
}

export default connect(mapStateToProps, {
  addLayer
})(ARLayersPage)