// Inspired from https://material-ui.com/components/lists/#SelectedListItem.js

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))


export default function SelectedListItem(props) {
  const classes = useStyles()
  
  return (
    <div className={classes.root}>

      <List component="nav" className="ListMenuList">
        
        {
          props.items.map((itemValue, ind) => {
            const iconStyle = {}
            if (itemValue.icon){
              iconStyle.backgroundUrl = itemValue.icon
            }
            return (
              <ListItem
                key = {ind}
                button
                selected = {itemValue.id === props.itemSelectedId}
                onClick={
                  (event) => {
                    props.select(itemValue.id)
                  }
                }
              >
                <div className='ListMenuIcon'
                style = { iconStyle } ></div>
                <ListItemText primary={ itemValue.label } />
              </ListItem>
              )
          })
        }
        <Divider />
        
      </List>

    </div>
  )
}