import { NavLink, Route, Switch } from "react-router-dom"
import PropTypes from "prop-types"
import React from "react"
import { hot } from "react-hot-loader"

import LoginPage from "./containers/LoginPage"
import HomePage from "./containers/HomePage"
import ARAppsPage from "./containers/ARAppsPage"
import ARLayersPage from "./containers/ARLayersPage"
import NotFoundPage from "./NotFoundPage"

import NavBar from './NavBar'

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  componentDidMount() {
    this.props.recoverUserSession()
  }

  render() {
    return (this.props.userLogged) ? (
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/home" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/apps" component={ARAppsPage} />
          <Route path="/layers" component={ARLayersPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    ) : (
      <div>
        <LoginPage />
      </div>
    )
  }
}

App.propTypes = {
  children: PropTypes.element
}

export default hot(module)(App)