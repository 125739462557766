import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import NavBar from './containers/NavBar'
import ListMenuApps from './containers/ListMenuApps';
import ARAppDetails from './containers/ARAppDetails';



const ARAppsPage = ({appSelected, apps, addARApp}) => {
  const handleAddApp = addARApp.bind(null, 'NEW APP')


  return (
    <div>
     <NavBar />

     <CssBaseline />
     
     <div className='ARAppsPageContainer'>

       { (apps.length === 0) ?
        (
          <Paper  className='ARAppPageNoticeNoApps'>
            <Typography>
              There is no AR app yet. An AR App servers multiple AR layers through a specific URL.
            </Typography>
            <Typography>
              You need to create one in order to use your AR Apps.<br/><br/>
            </Typography>
            <div className="center">
              <Button color="primary" variant="contained" onClick={handleAddApp}>Create a new app</Button>
            </div>
          </Paper>
          ) : (
          <div className='ARAppPageLeftColumn'>
            <ListMenuApps />
            <Fab color="primary" aria-label="add" className="ARAppsPageAddFloatButton" onClick={handleAddApp}>
              <AddIcon />
            </Fab>
          </div>
        )}

        <div className='ARAppPageRightColumn'>
         { (appSelected) ? (<ARAppDetails />) : (
             <Paper className='ARAppPageNotice'>
               <h1>AR Apps</h1>
               <Typography>
                An AR App servers multiple AR layers through a specific URL.
                Select an AR App using the left menu
               </Typography>
             </Paper>
           )
         }
        </div>
      </div>
    </div>
  );
};

export default ARAppsPage; 
