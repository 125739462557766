import { connect } from 'react-redux'

import ListMenu from '../ListMenu'
import { selectARApp } from '../../actions/ARAppActions'


const mapStateToProps = (state) => {
  return {
    items: state.imarReducer.apps,
    itemSelectedId: state.imarReducer.selectedAppId
  }
}
export default connect(mapStateToProps, {
  select: selectARApp
})(ListMenu)
