import { connect } from 'react-redux'

import ListMenu from '../ListMenu'
import { selectLayer } from '../../actions/layerActions'


const mapStateToProps = (state) => {
  return {
    items: state.imarReducer.layers,
    itemSelectedId: state.imarReducer.selectedLayerId
  }
}
export default connect(mapStateToProps, {
  select: selectLayer
})(ListMenu)
