import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import NavBar from './containers/NavBar';
import ListMenuLayers from './containers/ListMenuLayers';
import ARLayerDetails from './containers/ARLayerDetails';


// Add layer button ref: https://material-ui.com/components/buttons/
const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  }
}));


const ARLayersPage = ({layerSelected, layers, addLayer}) => {
  const classes = useStyles()
  const handleAddLayer = addLayer.bind(null, 'NEW LAYER')

  return (
    <div>
     <NavBar />
     <CssBaseline />

     <div className='ARLayersPageContainer'>

       { (layers.length === 0) ?
        (
          <Paper  className='ARLayerPageNoticeNoLayers'>
            <Typography>
              There is no AR layer yet.<br/><br/>
            </Typography>
            <div className="center">
              <Button color="primary" variant="contained" onClick={handleAddLayer}>Create a new layer</Button>
            </div>
          </Paper>
          ) : (
          <div className='ARLayerPageLeftColumn'>
            <ListMenuLayers />
            <Fab color="primary" aria-label="add" className="ARLayersPageAddFloatButton" onClick={handleAddLayer}>
              <AddIcon />
            </Fab>
          </div>
        )}

       <div className='ARLayerPageRightColumn'>
         { (layerSelected) ? (<ARLayerDetails />) : (
             <Paper className='ARLayerPageNotice'>
               <h1>AR Layers</h1>
               <Typography>
                An AR Layer groups a bunch of target images to recognize simultaneously.<br/>
                Select an AR layer using the left menu
               </Typography>
             </Paper>
           )
         }
       </div>
     </div>

    </div>
  );
};

export default ARLayersPage; 
