import React, { Component } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

// Display an error message
// Inspired from: https://material-ui.com/components/snackbars/

class ErrorSnackBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.setState({open: false})
  }


  render (){
    return (!this.state.open) ? null : (
      <Snackbar
        className = 'ErrorSnackBar'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration = {4000}
        open = {true}
        onClose={this.handleClose}>

        <SnackbarContent className = 'ErrorSnackBarContent'
          message={
            <span className = 'ErrorSnackBarMessage'>
              <ErrorIcon className = 'ErrorSnackBarIcon' />
              {this.props.message}
            </span>
          }
        />

      </Snackbar>

    )
  }
}

export default ErrorSnackBar