import { connect } from 'react-redux'

import App from '../App'
import { recoverUserSession } from '../../actions/userActions'

const mapStateToProps = (state) => {
  return {
    userLogged: state.imarReducer.user.logged
  }
}

export default connect(mapStateToProps, {
  recoverUserSession
})(App)
