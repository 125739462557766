import { connect } from 'react-redux'

import LayerSelector from '../LayerSelector'


const mapStateToProps = (state) => {
  return {
    layers: state.imarReducer.layers,
  }
}
export default connect(mapStateToProps, null)(LayerSelector)
