import { connect } from 'react-redux'

import Welcome from '../Welcome'


const mapStateToProps = (state) => {
  const user = state.imarReducer.user
  return {
    userName: user.name,
  }
}
export default connect(mapStateToProps, null)(Welcome)
