/*
 Constants to set application settings
 */

// URL of the backoffice server:
export const SERVER_BO_URL = 'https://imarbo.webar.rocks';

export const SERVER_BO_URL_DEV = 'https://imarbo.webar.rocks';
// Only if you run the server part locally (require to install mysql and other stuffs...):
//export const SERVER_BO_URL_DEV = 'http://localhost:8101';


// URL of the websockets service:
export const SERVER_WS_URL = 'wss://imarws.webar.rocks:443';


// publikeys of google sign-in (set a new one here: https://console.developers.google.com/apis/credentials?folder=&organizationId=&project=imar-244205 )
// for https://image.webar.rocks (prod):
export const GOOGLE_SIGNIN_PUBLICKEY = '588692572506-md1k0qru93gok5fpk6r4agpqek67oas1.apps.googleusercontent.com';

// for http://localhost:3000 (dev):
export const GOOGLE_SIGNIN_PUBLICKEY_DEV = '588692572506-6991plee7cjbfprod4k8ni9irtvsj80n.apps.googleusercontent.com';


// PHASH COMPUTATION:
export const PHASH_TYPE = 1     // type of the pHash. 0 -> greyScale, 1 -> RGB
export const PHASH_LENGTH = 384 // in bits, length of the pHash
export const PHASH_N = 128      // size in pixels of the image used to compute the pHash

export const THUMBNAIL_AREA = 256*256 