import {
  UPDATE_ARAPPS,
  SELECT_ARAPP,
} from '../constants/actionTypes'
import { fetchHelper } from '../helpers/helpers'
import {
  logout
} from './userActions'


const processAnswer = (dispatch, answer) => {
  if (answer['success']){
    dispatch({
      type: UPDATE_ARAPPS,
      ...answer
    })
  } else if(answer['error'] && answer['error'] === 'INVALID USER') {
    dispatch(logout())
  }
}


export function addARApp(label){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/addARApp', { label }).then((answer) => {
      processAnswer(dispatch, answer)
      if (answer['success']){
        dispatch(selectARApp(answer['newAppID']))
      }
    }).catch((err) => {
      console.log('WARNING: cannot addARApp because ', err)
    })
  }
  
}


export function deleteARApp(appID){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/deleteARApp', { id: appID }).then((answer) => {
      processAnswer(dispatch, answer)
      if (answer['success']){
        dispatch(selectARApp(-1))
      }
    }).catch((err) => {
      console.log('WARNING: cannot deleteARApp because ', err)
    })
  }
  
}


export function updateARApp(appID, label, url, layers, NNDetect, NNTracking, thresholdDetectFactor){
  
  return (dispatch) => {
    //check if the 
    fetchHelper('/updateARApp', { id: appID, label, url, layers, NNDetect, NNTracking, thresholdDetectFactor}).then((answer) => {
      processAnswer(dispatch, answer)
    }).catch((err) => {
      console.log('WARNING: cannot updateARApp because ', err)
    })
  }
  
}


export function selectARApp(appID){
  return {
    type: SELECT_ARAPP,
    appID
  }
}