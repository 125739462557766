import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import logoImageSRC from '../images/Astar600.png'

export default function Welcome ({ userName }) {
  return (
    <div className="WelcomeContainer">
      <CssBaseline />
      <Container className="WelcomeContent">
        <Typography component="div" style={{ height: '100vh', marginTop: '8em' }}>
          <img src={logoImageSRC} className='WelcomeLogoImage' />
          <h1> 
            Hi {userName}<br/>Welcome to WebAR.rocks.image backoffice
          </h1>
          <Divider />
          <ol className='WelcomeDescription'>
            <li>Create an AR Layer,</li>
            <li>Import your target images into your AR layer. Add a label for each image,</li>
            <li>Create an AR App grouping one or multiple AR layers,</li>
            <li>Clone <Link href='https://github.com/xavierjs/WebAR.rocks.image' target='_blank'>WebAR.rock.image Github repository</Link> to start working with a basic application retrieving your target images and displaying their label in 3D,</li>
            <li>Tweak the sample application to replace the display of 3D text label by your own application (it is based on <Link href='https://threejs.org' target='_blank'>THREE.js</Link>)</li>
            <li>Deploy your application at the URL specified into this backoffice.</li>
          </ol>
        </Typography>
      </Container>
    </div>
  );
}