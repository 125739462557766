import Cookies from 'universal-cookie'

import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_LOGERR,
  DO_NOTHING
} from '../constants/actionTypes'
import { fetchHelper } from '../helpers/helpers'

// As soon as the user is logged in,
// We setup cookies to store userId and token
// So that each request to the server will automatically
// contain these information
const _cookies = new Cookies()
const set_cookies = (userId, token) => {
  const opts = {
    path: '/',
    sameSite: false,
    expires: null,
    httpOnly: false
  }
  _cookies.set('userId', userId, opts)
  _cookies.set('token', token, opts)
}


export function recoverUserSession(){
  const cookieUserId = _cookies.get('userId')
  const token = _cookies.get('token')

  if (cookieUserId && cookieUserId !== 'null' && token){
    //session cookies have been found, check the session and get data from the server:
    return (dispatch) => {
      //check if the 
      fetchHelper('/recoverSession', {}).then((answer) => {
        if (answer['success']){
          dispatch({
            type: USER_LOGIN,
            ...answer
          })
        }
      }).catch((err) => {
        console.log('WARNING: cannot login because ', err)
      })
    }
  } else {
    return {
      type: DO_NOTHING
    }
  }
}


export function login(authInfos, userName){

  console.log('[INFO] in userActions: authInfos = ', authInfos);
  // This is an asynchronous action
  // so we don't return directly the reducer
  // we return a function taking the dispatch function as argument
  // and dispatch should be launched with the reducer as argument
  return (dispatch) => {

    // Send a request to the BO server which will:
    //   - check authInfos (to avoid hacker providing googleId of someone else)
    //   - open the session (add a document in the MySQL database sessions)
    //   - send back user data (if the user has already logged in)
    
    fetchHelper('/login', authInfos).then((answer) => {
      console.log('INFO in userActions.js - login: answer =', answer)

      if (answer['success']){ // login success:
        set_cookies(answer['userId'], answer['token'])
        dispatch({
          type: USER_LOGIN,
          ...answer
        })
      } else {
        dispatch({  //login fails
          type:USER_LOGERR
        })
      }
    }).catch((err)=>{
      console.log('ERROR in userActions.js - login: ', err)
      dispatch({
        type: USER_LOGERR
      })
    });

  }
  /*return {
    type: USER_LOGIN,
    userId: userId,
    userGoogleId: authInfos.googleId,
    userName: userName
  }*/
}


export function logout(){
  console.log('INFO in userActions: logout()')

  return (dispatch) => {
    fetchHelper('/logout', {}).then((answer) => {
      console.log('INFO in userActions.js - logout: answer =', answer)
    }).catch((err)=>{
      console.log('ERROR in userActions.js - logout: ', err)
    }).finally(()=>{
      set_cookies(null, null)

      dispatch({
        type: USER_LOGOUT
      })
    });
  }
}